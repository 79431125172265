import React from 'react'
import LangSwitcher from './../lang-switcher/lang-switcher'
import './menu.scss'
import LogoSVG from './../../assets/images/logo.svg'
import EnvelopeSVG from './../../assets/icons/envelope.svg'
import {Link} from 'gatsby'
import {t} from "../../utils/utils"

const menuItems = [
  { name: '¿Cómo funciona?', to: '/#anchor-how-it-works' },
  { name: 'El fotomatón', to: '/#anchor-fotomaton'},
  { name: 'Happy pack', to: '/#anchor-happy-pack'},
  { name: 'Eventos', to: '/#anchor-events'}
];

const Menu = ({lang, messages, visible}) => {
  const classHidden = !visible ? 'hidden': '';
  const contactHrefs = {
    'es': '/contacto',
    'eu': '/eu/kontaktua',
    'en': '/en/contact'
  };
  return (
    <>
    <nav className={`site-navbar ${classHidden}`}>
      <LogoSVG />
      <ul className="site-menu">
        {menuItems.map((i, key) => {
          return(
            <li key={key}><Link to={ lang !== 'es' ? '/' + lang + i.to : '' + i.to }>{ t("nav-" + (key+1), messages)}</Link></li> 
          )
        })}
      </ul>
      <LangSwitcher lang={lang}/>
      <Link className="link-contact" to={contactHrefs[lang]}>
        <EnvelopeSVG />
      </Link>
    </nav>
    <Link className="link-contact-fixed" to={contactHrefs[lang]}>
      <EnvelopeSVG />
    </Link>
    </>
  )
}

export default Menu