/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Menu from "./menu/menu"
import Footer from "./footer/footer"
import CustomCookieConsent from "./custom-cookie-consent/custom-cookie-consent"
import Context from "./../utils/context"

import './../assets/styles/main.scss'



const Layout = ({ lang, children, messages }) => {
  if (typeof window !== "undefined") {
    // eslint-disable-next-line global-require
    require("smooth-scroll")('a[href*="#"]')
  }

  const isMenuVisible = (scrollPosition, footerRef) => {
    if(footerRef && scrollPosition && footerRef.getBoundingClientRect().top < window.innerHeight) {
      return false;
    }
    return true;
  }

  let footerRef = null;


  return (
    <>
      <Context.Consumer>
        {context => {
          const { scrollPosition } = context.data;
          return (
            <>
              <Menu lang={lang} messages={messages} visible={isMenuVisible(scrollPosition, footerRef)}/>
              <main id="site-wrapper">{children}</main>
              <div ref={el => footerRef = el }> 
                <Footer messages={messages}/>
              </div>
              <CustomCookieConsent lang={lang}/>
            </>
          )
        }}
      </Context.Consumer>
      
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
