import React from 'react'
import {Link} from 'gatsby'
import './lang-switcher.scss'

const langs = [
  {text: 'ES', code: 'es', location: 'es-ES'},
  {text: 'EUS', code: 'eu', location: 'eu'},
  {text: 'EN', code: 'en', location: 'en'}
];

class LangSwitcher extends React.Component {
  render() {
    return(
      <ul className="lang-switcher">
        {langs.map((l, key) => {
          return (
            <li key={key} className={ this.props.lang === l.code ? 'active': ''}><Link to={l.code !== 'es' ? '/' + l.code : '/'}>{l.text}</Link></li>
          )
        })}
      </ul>
    )
  }
}

export default LangSwitcher