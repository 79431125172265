import  React from "react"
import Row from "../../components/grid/row"
import Col from "../../components/grid/col"
import './footer.scss'
import IrontecSVG from "./../../assets/images/irontec.svg"
import WorkoholicsSVG from "./../../assets/images/workoholics.svg"
import {t} from '../../utils/utils'

const Footer = ({messages}) => {
  return(
    <>
      <footer className="site-footer">
        <Row wrap={true}>
          <Col md={12} lg={6}>
            <p dangerouslySetInnerHTML={{__html: t('footer-description', messages)}} />
          </Col>
          <Col lg={5} lgOffset={1}>
            <ul className="contact-info">
              <li><strong>Tel:</strong> <a href="tel:+34944048128">+ 34 944 048 182</a></li>
              <li><strong>Mail:</strong> <a href="mailto:hello@happyselfie.es">hello@happyselfie.es</a></li>
            </ul>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="brand">
              <IrontecSVG/>
              <WorkoholicsSVG/>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <nav className="nav-legal">
              <ul>
                <li><a target="_blank" href="/politica-de-privacidad">{t('footer-privacidad', messages)}</a></li>
                <li><a target="_blank" href="/politica-de-cookies">{t('footer-cookies', messages)}</a></li>
              </ul>
            </nav>
          </Col>
        </Row>
      </footer>
    </>
  )
}

export default Footer